import { default as React } from 'react';
import { store, view } from 'react-easy-state';
import styled from 'react-emotion';

import Nav from '../components/Nav';

import { colors, widths } from '../styles/variables';

import bind from '../util/pureBind';

const LINKS: string[] = ['Hey', 'Projects', 'Docs', 'Travel', 'Book_Club'];

const LEFT = 15;
const TOP = 20;

const Hamburger = styled.div`
	display: block;
	left: ${LEFT}px;
	position: fixed;
	top: ${TOP}px;
	user-select: none;
	z-index: 2000;
`;

interface LineProps {
	index?: number;
	show: boolean;
}

const HamburgerLines = styled.span`
		background: ${colors.light};
		border-radius: 3px;
		display: none;
		height: 4px;
		margin-bottom: 5px;
		position: relative;
		transform-origin: 4px 0px;
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					opacity 0.55s ease;
		width: 33px;
		z-index: 3;
		${({ show }: LineProps) => {
			return show
				? `
					opacity: 1;
					transform: rotate(45deg) translate(-2px, -1px);
					background: ${colors.light};
				`
				: '';
		}}
		${({ index }: LineProps) => {
			if (index === 0) {
				return 'transform-origin: 0% 0%;';
			}
			if (index === 2) {
				return 'transform-origin: 0% 100%;';
			}
		}}
		${({ index, show }: LineProps) => {
			if (show && index === 1) {
				return `
						opacity: 0;
						transform: rotate(0deg) scale(0.2, 0.2);
					`;
			}
			if (show && index === 2) {
				return `
						opacity: 1;
						transform: rotate(-45deg) translate(0, -1px);
					`;
			}
			return '';
		}}
		@media only screen and (max-width: ${widths.md}px) {
			display: block;
		}
`;

const Input = styled.input`
	cursor: pointer;
	display: block;
	height: 33px;
	opacity: 0;
	padding: 40px 60px 10px 60px;
	position: fixed;
	width: 33px;
	z-index: 5;
`;

const nav = store({ show: false });

const setShow = (show: boolean) => {
	nav.show = show;
};

const NavGroup: React.FunctionComponent = () => {
	return (
		<div>
			<Hamburger id="menuToggle">
				<Input onChange={bind(setShow, !nav.show)} type="checkbox" />
				<HamburgerLines show={nav.show} />
				<HamburgerLines index={1} show={nav.show} />
				<HamburgerLines index={2} show={nav.show} />
			</Hamburger>
			<Nav links={LINKS} show={nav.show} />
		</div>
	);
};

export default view(NavGroup);
