import React from 'react';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import styled from 'react-emotion';

import { boxShadow } from '../styles/variables';
import bind from '../util/pureBind';

interface Props {
	containerWidth: number;
	fixed?: FixedObject;
	fluid?: FluidObject;
	index: number;
	onClick?: () => void;
	width?: number;
}

const DEFAULT_PROPS = {
	animation: 'zoom-in-up',
	containerWidth: 960,
};

interface NumberMap {
	[s: string]: number;
}

const widthMap: NumberMap = {
	0: 0.9,
	1: 0.46,
	2: 0.46,
	3: 0.9,
	4: 0.28,
	5: 0.37,
	6: 0.28,
	7: 0.46,
	8: 0.46,
};

interface BrickProps {
	containerWidth: number;
	'data-aos': string;
	i: number;
	onClick?: () => null;
	width?: number;
}

const Brick = styled.div`
	align-self: flex-end;
	cursor: ${({ onClick }: BrickProps) => {
		return onClick ? 'pointer' : 'default';
	}};
	pointer;
	flex: auto;
	height: auto;
	min-width: 150px;
	margin: 2.5%;
	width: ${({ containerWidth, i, width }: BrickProps) => {
		if (width) {
			return width * containerWidth;
		}
		return widthMap[Number(i) % 9] * Number(containerWidth);
	}}px;
`;

const ImgContainer = styled.div`
	border-radius: 10px;
	box-shadow: ${boxShadow};
	overflow: hidden;
`;

const Node = styled.div`
	align-self: center;
	margin: auto;
`;

const ANIMATIONS: string[] = ['zoom-in-up', 'zoom-in-right', 'zoom-in-left'];
const AnimatedImage = ({ containerWidth, fixed, fluid, index, onClick, width }: Props) => {
	const animation = ANIMATIONS[index % 3];
	return (
		<Node>
			<Brick
				className="aos-item masonry-brick"
				containerWidth={containerWidth}
				data-aos={animation}
				i={index}
				onClick={onClick && bind(onClick, fluid, index)}
				width={width}
			>
				<ImgContainer>
					<div className="aos-item__inner">
						<Img fixed={fixed} fluid={fluid} />
					</div>
				</ImgContainer>
			</Brick>
		</Node>
	);
};

AnimatedImage.defaultProps = DEFAULT_PROPS;

export default AnimatedImage;
