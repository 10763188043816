import * as React from 'react';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { StyledOtherComponent } from 'create-emotion-styled';

interface NavIconProps {
	fluid: any;
	to: string;
}

interface StyledIconProps {
	children: React.ReactNode;
	href: string;
	rel: string;
	target: string;
}

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 8px;
	width: 100%;
`;

const StyledIcon: StyledOtherComponent<StyledIconProps, {}, {}> = styled.a`
	width: 30%;
`;

const NavIcon: React.FunctionComponent<NavIconProps> = ({ fluid, to }) => {
	return (
		<IconContainer>
			<StyledIcon href={to} rel="noopener noreferrer" target="_blank">
				<Img fluid={fluid} />
			</StyledIcon>
		</IconContainer>
	);
};

export default NavIcon;
