import { injectGlobal } from 'emotion';
import { breakpoints, colors, dimensions, fonts } from './variables';
import { getEmSize } from './mixins';

import { darken } from '../util/style';

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  html {
	box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
	box-sizing: inherit;
  }

  html {
	font-size: ${dimensions.fontSize.regular}px !important;
	line-height: ${dimensions.lineHeight.regular} !important;
  }

  body {
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	font-family: ${fonts.sansSerif};
	color: ${darken(colors.dark, 5)};
	background-color: ${colors.darkAccent};
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
  }

  a {
	color: ${colors.mainBrand};
	text-decoration: none;

	&:hover,
	&:focus {
	  text-decoration: underline;
	}
  }

  img {
	max-width: 100%;
	object-fit: contain;
	position: relative;
  }

  figure {
	margin: 2rem 0;
  }

  figcaption {
	font-size: 80%;
  }

  table {
	width: 100%;
	margin-bottom: 1rem;
	border: 1px solid ${colors.light};
	font-size: 85%;
	border-collapse: collapse;
  }

  td,
  th {
	padding: .25rem .5rem;
	border: 1px solid ${colors.light};
  }

  th {
	text-align: left;
  }

  tbody {
	tr {
	  &:nth-child(odd) {
		td {
		  background-color: ${colors.light};
		}
		tr {
		  background-color: ${colors.light};
		}
	  }
	}
  }

  h1, h2, h3, h4, h5, h6 {
	margin-top: 1.414rem;
	margin-bottom: .5rem;
	color: ${colors.light};
	font-weight: 600;
	line-height: ${dimensions.lineHeight.heading};
	text-rendering: optimizeLegibility;
  }

  h1 {
	margin-top: 0;
	font-size: ${dimensions.headingSizes.h1}rem;
  }

  h2 {
	font-size: ${dimensions.headingSizes.h2}rem;
  }

  h3 {
	font-size: ${dimensions.headingSizes.h3}rem;
  }

  h4, h5, h6 {
	font-size: ${dimensions.headingSizes.h4}rem;
  }

  p {
	margin-top: 0;
	margin-bottom: 1rem;
  }

  strong {
	color: ${colors.black};
  }

  ul,
  ol,
  dl {
	margin-top: 0;
	margin-bottom: 1rem;
  }

  dt {
	font-weight: bold;
  }

  dd {
	margin-bottom: .5rem;
  }

  hr {
	position: relative;
	margin: 1.5rem 0;
	border: 0;
	border-top: 1px solid ${colors.light};
  }

  blockquote {
	margin: .8rem 0;
	padding: .5rem 1rem;
	border-left: .25rem solid ${colors.light};
	color: ${colors.darkAccent};

	p {
	  &:last-child {
		margin-bottom: 0;
	  }
	}

	@media (min-width: ${getEmSize(breakpoints.md)}em) {
	  padding-right: 5rem;
	  padding-left: 1.25rem;
	}
  }
`;
