import { useEffect, useState } from 'react';

const getContainerWidth = (querySelector: string) => {
	let width;
	if (typeof document !== 'undefined') {
		const el = document.querySelector(querySelector);
		if (el) {
			width = el.getBoundingClientRect().width;
		}
	}
	return width;
};

export const useContainerWidth = (querySelector: string, props: any) => {
	const [containerWidth, setContainerWidth] = useState(getContainerWidth(querySelector));

	const handleResize = () => {
		setContainerWidth(getContainerWidth(querySelector));
	};

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [props]);

	return containerWidth;
};

export const useWindowDimensions = () => {
	// use iphone dimensions because too small is better than too big
	const DEFAULT_DIMENSIONS = { height: 700, width: 600 };
	const [dimensions, setDimensions] = useState(DEFAULT_DIMENSIONS);

	const handleResize = () => {
		setDimensions({
			height: window.innerHeight,
			width: window.innerWidth,
		});
	};

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return dimensions;
};

export const useInterval = (cb: () => void, interval = 1000, effects: any[] = []) => {
	useEffect(() => {
		const id = setInterval(() => {
			cb();
		}, interval);

		return () => {
			clearInterval(id);
		};
	}, effects);
};

export const useTimeout = (cb: () => void, timeout = 1000, effects: any[] = []) => {
	useEffect(() => {
		const id = setTimeout(() => {
			cb();
		}, timeout);

		return () => {
			clearTimeout(id);
		};
	}, effects);
};
