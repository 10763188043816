import { default as React } from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import AOS from 'aos';
import 'aos/dist/aos.css';

import 'modern-normalize';
import '../styles/normalize';

import NavGroup from '../components/NavGroup';
import LayoutRoot from '../components/LayoutRoot';
import LayoutMain from '../components/LayoutMain';

type StaticQueryProps = {
	site: {
		siteMetadata: {
			title: string
			description: string,
		},
	},
};

class IndexLayout extends React.Component {
	componentDidMount() {
		AOS.init({
			anchorPlacement: 'center-bottom',
			duration: 300,
			easing: 'ease-in-out',
			mirror: true,
		  });

	}
	queryRender = (children: React.ReactNode) => {
		return (data: StaticQueryProps) => (
			<LayoutRoot>
				<Helmet
					title={data.site.siteMetadata.title}
					meta={[
						{ name: 'description', content: data.site.siteMetadata.description },
						{ name: 'keywords', content: 'gatsbyjs, gatsby, javascript, typescript, react, graphql' },
					]}
				/>
				<NavGroup />
				<LayoutMain>{children}</LayoutMain>
			</LayoutRoot>
		);
	}

	render() {
		return (
			<StaticQuery
				query={graphql`
					query IndexLayoutQuery {
						site {
							siteMetadata {
								title
								description
							}
						}
					}
				`}
				render={this.queryRender(this.props.children)}
			/>
		);
	}
}

export default IndexLayout;
