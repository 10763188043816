import * as React from 'react';
import { get } from 'lodash';
import { graphql, Link, StaticQuery } from 'gatsby';
import styled from 'react-emotion';

import { colors, fonts, heights, widths } from '../styles/variables';

import Container from './Container';
import NavIcon from '../components/NavIcon';

const IconContainer = styled.div`
	padding: 10px 0;
`;

const NavInner = styled.div`
	align-items: space-around;
	background-color: ${colors.dark};
	display: flex;
	flex-direction: column;
	height: 40%;
	justify-content: space-between;
`;

const NavLink = styled(Link)`
	color: ${colors.lightAccent};
	font: ${fonts.sansSerif};
	font-size: 1.5rem;
	font-weight: 600;
	padding: 10px;
	&:hover,
	&:focus {
		color: ${colors.light};
		font-weight: 600;
		text-decoration: none;
	}
`;

interface StyledNavProps {
	show: boolean;
}

const StyledNav = styled.nav`
	background-color: ${colors.dark};
	height: 100vh;
	padding-top: ${heights.nav}px;
	position: fixed;
	width: ${widths.nav};
	z-index: 20;

	@media only screen and (max-width: ${widths.md}px) {
		${({ show }: StyledNavProps) => {
			if (!show) {
				return `
					transform: translate(-100%, 0);
					transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
				`;
			}
			return `
				transform: translate(0, 0);
				transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

			`;
		}};
	}
`;

interface NavProps {
	data?: any;
	links: string[];
	show: boolean;
}

const Nav: React.FunctionComponent<NavProps> = ({ data, links, show }) => {
	const gitFluid = get(data, 'git.childImageSharp.fluid');
	const linkedInFluid = get(data, 'linkedIn.childImageSharp.fluid');
	return (
		<StyledNav show={show}>
			<Container>
				<NavInner id="nav">
					{links.map(url => (
						<div key={url}>
							<NavLink
								activeStyle={{ color: colors.light }}
								to={`/${url === 'Hey' ? '/' : url.toLowerCase()}`}
							>
								{url.replace('_', ' ')}
							</NavLink>
						</div>
					))}
					<IconContainer>
						<NavIcon
							fluid={linkedInFluid}
							to="http://www.linkedin.com/in/andrewmgonzales"
						/>
						<NavIcon fluid={gitFluid} to="http://www.github.com/andrewgonzales" />
					</IconContainer>
				</NavInner>
			</Container>
		</StyledNav>
	);
};

export default (props: NavProps) => (
	<StaticQuery
		query={graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
				git: file(relativePath: { regex: "/nav.*Git/" }) {
					...fluidImage
				}
				linkedIn: file(relativePath: { regex: "/nav.*In/" }) {
					...fluidImage
				}
			}
		`}
		// tslint:disable-next-line:jsx-no-lambda
		render={data => <Nav data={data} {...props} />}
	/>
);
