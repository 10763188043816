import * as React from 'react';
import styled from 'react-emotion';

import { colors, dimensions, widths } from '../styles/variables';

const StyledPage = styled.div`
	background-color: ${colors.darkAccent};
	display: block;
	flex: 1;
	position: relative;
	padding: ${dimensions.containerPadding}rem;
	margin-left: ${widths.nav - 10}px;

	@media only screen and (max-width: ${widths.md}px) {
		margin-left: 0;
		padding: 5px;
	}
`;

interface PageProps {
	className?: string;
}

const Page: React.FunctionComponent<PageProps> = ({ children, className }) => (
	<StyledPage className={className}>{children}</StyledPage>
);

export default Page;
