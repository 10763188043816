import * as React from 'react';
import styled from 'react-emotion';

const StyledLayoutRoot = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

interface LayoutRootProps {
	className?: string;
}

const LayoutRoot: React.FunctionComponent<LayoutRootProps> = ({ children, className }) => (
	<StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
);

export default LayoutRoot;
