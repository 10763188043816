import { darken } from '../util/style';

/* tslint:disable:max-line-length */

export const boxShadow = '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)';
export const colors = {
	accent: '#D4A556',
	background: '#49496D',
	black: '#000000',
	dark: '#292333',
	darkAccent: '#49496D',
	grayCalm: 'rgba(0, 0, 0, 0.54)',
	grayCopy: 'hsla(270, 15.797828016000002%, 0%, 0.88)',
	grayDark: 'hsla(270, 17.119554496%, 0%, 0.92)',
	light: '#F4F4F4',
	lightAccent: '#9D8CA6',
	mainBrand: '#6E6E77',
	mainText: darken('#292333', 5),
	// success: '#37b635',
	// warning: '#ec1818',
	white: '#FFFFFF',
};

export const fonts = {
	monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
	sansSerif:
		'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
	serif: 'Georgia, "Times New Roman", Times, serif',
};

export const breakpoints = {
	lg: 992,
	md: 768,
	sm: 576,
	xl: 1200,
	xs: 0,
};

export const widths = {
	lg: 960,
	md: 720,
	nav: 140,
	sm: 576,
	xl: 1140,
};

export const dimensions = {
	containerPadding: 1.5,
	fontSize: {
		large: 18,
		regular: 16,
	},
	headingSizes: {
		h1: 2.441,
		h2: 1.953,
		h3: 1.563,
		h4: 1.25,
	},
	lineHeight: {
		heading: 1.2,
		regular: 1.45,
	},
};

export const heights = {
	nav: 60,
};
