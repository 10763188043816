// https://codepen.io/jreyesgs/pen/MyKoRj
const addLight: any = (color: string, amount: number) => {
	const cc = parseInt(color, 16) + amount;
	const c = cc > 255 ? 255 : cc;
	const c2: string = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
	return c2;
};

const subtractLight = (color: string, amount: number) => {
	const cc = parseInt(color, 16) - amount;
	const c = cc < 0 ? 0 : cc;
	const c2 = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
	return c2;
};

const lightenDarken = (col: string, pct: number) => {
	const percent = Math.abs(pct);
	let color = col;
	const lightFunc = pct < 0 ? subtractLight : addLight;
	color = color.indexOf('#') >= 0 ? color.slice(1) : color;
	const newPercent: number = parseInt(`${(255 * percent) / 100}`, 16);
	color = `#${lightFunc(color.substring(0, 2), newPercent)}${lightFunc(
		color.substring(2, 4),
		newPercent
	)}${lightFunc(color.substring(4, 6), percent)}`;
	return color;
};

export const darken = (col: string, pct: number) => {
	return lightenDarken(col, -pct);
};

export const ellipsisOverflow = () => {
	return `
		min-width: 1px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`;
};

// adapted from https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
export const hexToRGB = (colorCode: string) => {
	let hex = colorCode;
	if (!hex || [4, 7].indexOf(hex.length) === -1) {
		return; // throw new Error('Bad Hex');
		/* eslint-disable */
		console.warn(`Invalid input(s) provided for hexToRGB function`);
		/* eslint-enable */
	}

	hex = hex.slice(1);
	// if shortcuts (#F00) -> set to normal (#FF0000)
	if (hex.length === 3) {
		hex = hex
			.split('')
			.map((el: string) => {
				return `${el}${el}`;
			})
			.join('');
	}

	const r = parseInt(hex.slice(0, 2), 16);
	const g = parseInt(hex.slice(2, 4), 16);
	const b = parseInt(hex.slice(4, 6), 16);

	return `${r}, ${g}, ${b}`;
};

export const lighten = (col: string, pct: number) => {
	return lightenDarken(col, pct);
};

export const option = (prop: any, style: any) => {
	return (props: any) => {
		return props[prop] ? style : '';
	};
};
